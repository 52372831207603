/*
 * Copyright ©  2018 - 2050 EasyLinkz Limited.
 *
 * All rights reserved.
 *
 * Email: super@easylinkz.com
 *
 * Licensed under the proprietary license.
 *
 * The source code contained herein is, and remains the property of EasyLinkz Limited.
 *
 * Dissemination, reproduction or other use of this code is strictly forbidden unless prior written permission
 * is obtained from EasyLinkz Limited.
 *
 * This software is provided by the author “as is” and any express or implied warranties, including,
 * but not limited to, the implied warranties of MERCHANTABILITY and FITNESS FOR A PARTICULAR PURPOSE are disclaimed.
 * In no event shall the author be liable for any direct, indirect, incidental, special, exemplary,
 * or consequential damages (including, but not limited to, procurement of substitute goods or services,
 * loss of use, data, or profits, or business interruption) however caused and on any theory of liability,
 * whether in contract, strict liability, or tort (including negligence or otherwise)
 * arising in any way out of the use of this software, even if advised of the possibility of such damage.
 *
 * You should have received a copy of the EasyLinkz Limited licence along with this program,
 * if not, write to the super@easylinkz.com.
 *
 */

import {HttpClient} from '@/services/http.service';
import {ConnectedSupplierInterface} from '@/shared/interfaces/store/connected-suppliers/connected-supplier.interface';
import {ConnectedSuppliersInterface} from '@/shared/interfaces/store/connected-suppliers/connected-suppliers.interface';
import {getModule, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {ISuppliersForm} from '@/shared/interfaces/common/suppliers-form.interface';
import {SelectInterface} from '@/shared/interfaces/common/select.interface';

@Module({
  name: 'ConnectedSuppliers',
  dynamic: true,
  namespaced: true,
  store
})
export class ConnectedSuppliers extends VuexModule {

  connectedSuppliers: ConnectedSupplierInterface[] = [];

  filteredSuppliers: ConnectedSupplierInterface[] = [];

  @MutationAction({
    mutate: ['connectedSuppliers', 'filteredSuppliers']
  })
  async getConnectedSuppliers(): Promise<ConnectedSuppliersInterface> {
    const suppliers = await HttpClient.get<ConnectedSupplierInterface[]>('/connected-suppliers?_sort=supplier:asc');
    return {
      connectedSuppliers: suppliers,
      filteredSuppliers: suppliers
    };
  }

  @Mutation
  filterConnectedSuppliers(form: ISuppliersForm): void {
    let result = this.connectedSuppliers.filter(item => {
      if (!form.search) {
        return true;
      }
      return item.supplier.toLowerCase().includes(form.search.toLowerCase())
    });
    result = result.filter(item => ConnectedSuppliers.selectFilter(item.type, form.type));
    result = result.filter(item => ConnectedSuppliers.selectFilter(item.integrationTypes, form.integrationType));
    result = result.filter(item => ConnectedSuppliers.selectFilter(item.settlements, form.settlement));
    result = result.filter(item => ConnectedSuppliers.selectFilter(item.formOfPayments, form.formOfPayment));
    result = result.filter(item => ConnectedSuppliers.selectFilter(item.supplierFeatures, form.features));
    result = result.filter(item => ConnectedSuppliers.selectFilter(item.benefits, form.benefits));
    this.filteredSuppliers = result;
  }

  static selectFilter(items: SelectInterface[], search: string[]): boolean {
    if (search.length === 0) {
      return true;
    }
    return items.filter(item => search.includes(item.value)).length === search.length;
  }

}

export const ConnectedSuppliersStore = getModule(ConnectedSuppliers);























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class Title extends Vue {

  @Prop()
  title: string;

  @Prop()
  subtitle: string;

  @Prop()
  link: string;

  @Prop()
  href: string;
}





















































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Title from '@/components/common/Title.vue';
import ProductLineSuppliersFooter from '@/components/product-line/ProductLineSuppliersFooter.vue';
import ProductLineSupplierItem from '@/components/product-line/ProductLineSupplierItem.vue';
import Search from '@/components/common/Search.vue';
import Select from '@/components/common/Select.vue';
import ProductLineForm from '@/components/product-line/ProductLineForm.vue';
import { ProductsModel } from '../../shared/interfaces/store/product-line/products.model';
import { ProductLineStore } from '../../store/modules/product-line';
import { DataTableHeader } from 'vuetify';
import DataTable from './DataTable.vue';
import FeatureStatus from './FeatureStatus.vue';
import { ConnectedSuppliersStore } from '../../store/modules/connected-suppliers';
import Filters from './Filters.vue';
import { suppliersData } from './data/index';
import { tFormat } from '../../shared/helpers/translate-format';
import { SelectInterface } from '../../shared/interfaces/common/select.interface';

@Component({
  components: {
    Filters,
    FeatureStatus,
    DataTable,
    ProductLineForm,
    Select,
    Search,
    ProductLineSupplierItem,
    ProductLineSuppliersFooter,
    Title
  }
})
export default class ProductLineSuppliers extends Vue {
  tableHeaders: DataTableHeader[] = [{
    text: this.$t('ps.general.suppliers.table-header.connector') as string,
    value: 'supplier'
  }, {
    text: this.$t('ps.general.suppliers.table-header.connector.marketing-airlines') as string,
    value: 'marketingAirlines'
  }];

  suppliersLogoMap = {
    'Lufthansa Group': 'https://ez-static.easylinkz.com/Lufthansa.svg',
    'British Airways': 'https://ez-static.easylinkz.com/BritishAir.svg',
    'Iberia': 'https://ez-static.easylinkz.com/iberia.svg',
    'American Airlines': 'https://ez-static.easylinkz.com/AA.svg',
    'United Airlines': 'https://ez-static.easylinkz.com/UA.svg',
    'Aegean Airlines': 'https://ez-static.easylinkz.com/aegean.svg',
    'LOT': 'https://ez-static.easylinkz.com/LO.svg',
    'Singapore Airlines': 'https://ez-static.easylinkz.com/Singapore.svg',
    'Emirates': 'https://ez-static.easylinkz.com/Emirates.svg',
    'flydubai': 'https://ez-static.easylinkz.com/Flydubai.svg',
    'Air France KLM': 'https://ez-static.easylinkz.com/AF.svg',
    'Travelfusion LCC': 'https://ez-static.easylinkz.com/TFL.svg',
    'Sabre': 'https://ez-static.easylinkz.com/SABRE.svg'
  };

  tableData = suppliersData.map(item => ({
    supplier: item.supplierName,
    marketingAirlines: item.marketingAirlines
  }));

  featureTypes = suppliersData[0].data.map(item => ({
    featureType: item.featureType
  }));

  activeFeatureType = this.featureTypes[0].featureType;

  featuresTableHeaders: DataTableHeader[] = [];

  featuresTableData = [];

  filters = {
    searchQuery: '',
    features: [] as string[]
  };

  filtersFeaturesSelect: SelectInterface[] = [];

  tFormat = tFormat;

  get content(): ProductsModel {
    return ProductLineStore.suppliers;
  }

  mounted(): void {
    ConnectedSuppliersStore.getConnectedSuppliers();
  }

  @Watch('activeFeatureType', { immediate: true })
  onWatchActiveFeatureType() {
    const data = suppliersData[0].data.find(i => i.featureType === this.activeFeatureType);
    this.featuresTableHeaders = Object.keys(
      data!.features
    ).map((key) => ({
      text: this.$t('ps.general.suppliers.features-table-header.' + tFormat(key)) as string,
      value: key
    }));
    //
    this.filtersFeaturesSelect = this.featuresTableHeaders.map(i => ({
      label: i.text,
      value: i.value
    }));
  }

  get computedTableData() {
    if (this.filters.searchQuery) {
      const reg = new RegExp(this.filters.searchQuery, 'gi');
      return this.tableData.filter(i => reg.test(i.supplier));
    }
    return this.tableData;
  }

  get computedFeaturesTableHeaders() {
    if (this.filters.features.length) {
      return this.featuresTableHeaders.filter(i => {
        return this.filters.features.includes(i.value);
      });
    }
    return this.featuresTableHeaders;
  }

  get computedFeaturesTableData() {
    const reg = new RegExp(this.filters.searchQuery, 'gi');
    this.featuresTableData = suppliersData.filter(item => {
      if (this.filters.searchQuery) {
        return reg.test(item.supplierName);
      }
      return item;
    }).map(
      item => item.data.find(i => i.featureType === this.activeFeatureType)?.features as never
    );
    return this.featuresTableData;
  }

  applyFilters(filters: any) {
    this.filters = filters;
  }
}

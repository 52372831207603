export class OverlayService {

  static get overlay(): HTMLDivElement {
    const el = document.getElementById('startup_overlay') as HTMLDivElement;
    return el || document.createElement('div');
  }

  static remove(): void {
    OverlayService.overlay.remove();
  }
}

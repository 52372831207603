

















































import Vue from 'vue';
import {Component, Prop, Ref} from 'vue-property-decorator';
import {directive, Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperClass, {SwiperOptions} from 'swiper';

@Component({
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  }
})
export default class ImageCarousel extends Vue {

  @Prop()
  images: string[];

  @Ref('swiper-images')
  readonly swiperImages!: { $swiper: SwiperClass };

  @Ref('swiper-thumbs')
  readonly swiperThumbs!: { $swiper: SwiperClass };

  imagesSwiperOptions: SwiperOptions = {
    roundLengths: true,
    loop: true,
    loopedSlides: 10,
    pagination: {
      type: 'custom',
      el: '.swiper-pagination',
      clickable: true,
      renderCustom(swiper, index, total) {
        const width = 100 / total;
        const offset = width * (index - 1);
        return `
          <div class="swiper-pagination-custom__container"></div>
          <span class="swiper-pagination-custom__bullet"
                style="width: ${width}%; left: ${offset}%"></span>
        `
      }
    }
  }

  imagesThumbsOptions: SwiperOptions = {
    centeredSlides: true,
    loop: true,
    touchRatio: 0.2,
    loopedSlides: 10,
    slidesPerView: 5,
    slideToClickedSlide: false,
    direction: 'vertical',
    spaceBetween: 24,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  }

  onThumbnailChange(val: Record<'activeIndex', number>) {
    this.swiperImages.$swiper.slideTo(val.activeIndex);
  }

  onImageChange(val: Record<'activeIndex', number>) {
    this.swiperThumbs.$swiper.slideTo(val.activeIndex);
  }

  updated(): void {
    this.swiperThumbs.$swiper.slideTo(0);
    this.swiperThumbs.$swiper.slideTo(0);
  }
}





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

@Component({})
export default class DataTable extends Vue {
  @Prop()
  headers: DataTableHeader[];
  @Prop()
  items: Record<string, unknown>[];

  get tableColWidth() {
    const _width = (100 / this.headers.length) + '%';
    return {
      'max-width': _width,
      'width': _width
    };
  }
}

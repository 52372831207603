































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {AssistantModel} from '@/shared/interfaces/store/product-line/assistant.model';
import {ProductLineStore} from '@/store/modules/product-line';
import ImageCarousel from '@/components/common/ImageCarousel.vue';
import Title from '@/components/common/Title.vue';
import Gallery from '@/components/common/Gallery.vue';
@Component({
  components: {Gallery, Title, ImageCarousel}
})
export default class ProductLineAssistant extends Vue {

  get content(): AssistantModel {
    return ProductLineStore.assistant;
  }

  selected: number = 0;
}









import { Component, Prop, Vue } from 'vue-property-decorator';
import { FeatureStatusEnum } from './interfaces/feature-status.enum';

@Component({})
export default class FeatureStatus extends Vue {
  @Prop()
  status: FeatureStatusEnum;

  statusText = {
    [FeatureStatusEnum.ACTIVATED]: 'Activated',
    [FeatureStatusEnum.IN_DEVELOPMENT]: 'Dev',
    [FeatureStatusEnum.NOT_SUPPORTED]: 'N/A',
    [FeatureStatusEnum.CAN_BE_ORDERED_WITH_SSR]: 'SSR',
    [FeatureStatusEnum.DIRECT_SETTLEMENT_WITH_TRAVELFUSION]: 'tfPay'
  };
}

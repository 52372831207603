














import {Component, Vue} from 'vue-property-decorator';
import {OverlayService} from '@/services/overlay.service';
import ProductLineMain from '@/components/product-line/ProductLineMain.vue';
import ProductLineMainFeatures from '@/components/product-line/ProductLineMainFeatures.vue';
import ProductLineAssistant from '@/components/product-line/ProductLineAssistant.vue';
import ProductLineProducts from '@/components/product-line/ProductLineProducts.vue';
import ProductLineSuppliers from '@/components/product-line/ProductLineSuppliers.vue';

@Component({
  components: {
    ProductLineSuppliers,
    ProductLineAssistant,
    ProductLineProducts,
    ProductLineMainFeatures,
    ProductLineMain
  }
})
export default class ProductLine extends Vue {

  mounted(): void {
    OverlayService.remove();
  }

};








































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Search from '../common/Search.vue';
import Select from '../common/Select.vue';
import { SelectInterface } from '../../shared/interfaces/common/select.interface';
import { FeatureStatusEnum } from './interfaces/feature-status.enum';

@Component({
  components: { Select, Search }
})
export default class Filters extends Vue {
  @Prop()
  featuresSelect: SelectInterface[];

  @Emit()
  apply() {
    return this.filters;
  }

  filters = {
    searchQuery: '',
    features: []
  };

  features: (Record<string, unknown> & SelectInterface)[] = [{
    color: '#19C0C0',
    label: this.$t('ps.general.suppliers.feature-status.activated') as string,
    value: FeatureStatusEnum.ACTIVATED
  }, {
    color: '#F98735',
    label: this.$t('ps.general.suppliers.feature-status.in-development') as string,
    value: FeatureStatusEnum.IN_DEVELOPMENT
  }, {
    color: '#F2536F',
    label: this.$t('ps.general.suppliers.feature-status.not-supported') as string,
    value: FeatureStatusEnum.NOT_SUPPORTED
  }, {
    color: '#8855FF',
    label: this.$t('ps.general.suppliers.feature-status.can-be-ordered-with-ssr') as string,
    value: FeatureStatusEnum.CAN_BE_ORDERED_WITH_SSR
  }, {
    color: '#4568EA',
    label: this.$t('ps.general.suppliers.feature-status.direct-settlement-with-travelfusion') as string,
    value: FeatureStatusEnum.DIRECT_SETTLEMENT_WITH_TRAVELFUSION
  }];

  clearAllClick() {
    this.filters = {
      searchQuery: '',
      features: []
    };
    this.apply();
  }

  applyClick() {
    this.apply();
  }
}

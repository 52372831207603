





















import Vue from 'vue';
import {Prop, VModel, Component} from 'vue-property-decorator';

@Component
export default class ProductLineSuppliersFooter extends Vue {

  @Prop()
  total: number;

  @VModel({ type: Number })
  page: number;

  next(): void {
    if (this.page === this.total) {
      return;
    }
    ++this.page;
  }

  prev(): void {
    if (this.page === 1) {
      return;
    }
    --this.page;
  }
}

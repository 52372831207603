










































import Vue from 'vue';
import {MainModel} from '@/shared/interfaces/store/product-line/main.model';
import {ProductLineStore} from '@/store/modules/product-line';
import {Component} from 'vue-property-decorator';

@Component
export default class ProductLineMain extends Vue {

  get content(): MainModel {
    return ProductLineStore.main;
  }

  breadcrumbs = [
    {
      text: this.$t('ps.general.menu.home'),
      disabled: false,
      href: '/',
    },
    {
      text: this.$t('ps.general.menu.product-line'),
      disabled: true,
      href: '/product-line',
    }
  ];
}















































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, VModel } from 'vue-property-decorator';
import { SelectInterface } from '@/shared/interfaces/common/select.interface';

@Component
export default class Select extends Vue {
  @Prop()
  items: SelectInterface[];
  @Prop()
  placeholder: string;
  @VModel({ type: Array })
  model: string[];

  get menuProps(): Record<string, unknown> {
    return {
      'offset-y': true,
      'content-class': 'box-shadow'
    };
  }

  get transformedItems() {
    return this.items.map(item => ({
      text: this.$t(item.label),
      value: item.value
    }));
  }

  get selectedAll(): boolean {
    return this.model.length === this.items.length;
  }

  get partialSelected(): boolean {
    return this.model.length > 0 && !this.selectedAll;
  }

  get icon(): string {
    if (this.selectedAll) {
      return 'mdi-checkbox-marked';
    }
    if (this.partialSelected) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }

  toggle() {
    this.$nextTick(() => {
      if (this.selectedAll) {
        this.model = [];
      } else {
        this.model = this.items.slice().map(item => item.value);
      }
    });
  }
}



























































import {Component, Emit, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import Search from '@/components/common/Search.vue';
import Select from '@/components/common/Select.vue';
import {SelectInterface} from '@/shared/interfaces/common/select.interface';
import {DictionariesStore} from '@/store/modules/dictionaries';
import {CustomForm} from '@/shared/form/custom-form';
@Component({
  components: {Search, Select}
})
export default class ProductLineForm extends Vue {

  @Prop()
  form: CustomForm;

  get supplierTypes(): SelectInterface[] {
    return DictionariesStore.supplierTypes;
  }

  get integrationTypes(): SelectInterface[] {
    return DictionariesStore.integrationTypes;
  }

  get settlements(): SelectInterface[] {
    return DictionariesStore.settlements;
  }

  get formsOfPayment(): SelectInterface[] {
    return DictionariesStore.formsOfPayment;
  }

  get features(): SelectInterface[] {
    return DictionariesStore.features;
  }

  get benefits(): SelectInterface[] {
    return DictionariesStore.benefits;
  }

  @Emit()
  apply(): void {}

  @Emit()
  clear(): void {}
}

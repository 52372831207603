













import Vue from 'vue';
import { Component, Emit, Prop, VModel } from 'vue-property-decorator';

@Component
export default class Search extends Vue {
  @Prop()
  placeholder: string;
  @VModel({ type: String })
  search: string;

  clearWait(): void {
    setTimeout(() => this.clear(), 100);
  }

  @Emit()
  clear() {
  }

}



































import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import {ProductsModel} from '@/shared/interfaces/store/product-line/products.model';
import {ProductLineStore} from '@/store/modules/product-line';
import Title from '@/components/common/Title.vue';
import Gallery from '@/components/common/Gallery.vue';
@Component({
  components: {Gallery, Title}
})
export default class ProductLineProducts extends Vue {

  get content(): ProductsModel {
    return ProductLineStore.products;
  }

  selected = 0;
}

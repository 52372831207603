




























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {ProductInterface} from '@/shared/interfaces/store/product-line/product.interface';
import ImageCarousel from '@/components/common/ImageCarousel.vue';

@Component({
  components: {ImageCarousel}
})
export default class Gallery extends Vue {

  @Prop()
  product: ProductInterface;

  @Prop()
  sideView: boolean;
}

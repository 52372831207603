




















import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {SelectInterface} from "@/shared/interfaces/common/select.interface";

@Component
export default class ProductLineSupplierItem extends Vue {

  @Prop()
  items: SelectInterface[];

  @Prop()
  limit: number;

  localLimit: number = 0;

  get buttonText(): string {
    return this.localLimit ? 'ps.general.suppliers.show-more' : 'ps.general.suppliers.show-less'
  }

  get filteredItems(): SelectInterface[] {
    if (!this.localLimit) {
      return this.items;
    }
    return this.items.slice(0, this.localLimit);
  }

  mounted(): void {
    this.localLimit = this.limit;
  }

  toggle(): void {
    this.localLimit = this.localLimit ? 0 : this.limit;
  }
}

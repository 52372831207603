
























import Vue from 'vue';
import {MainModel} from '@/shared/interfaces/store/product-line/main.model';
import {ProductLineStore} from '@/store/modules/product-line';
import {Component} from 'vue-property-decorator';

@Component
export default class ProductLineMainFeatures extends Vue {

  get content(): MainModel {
    return ProductLineStore.mainFeatures;
  }
}
